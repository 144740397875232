<template>
    <div class="view-header view-header--essai-details" v-if="pageTitle">
      <div class="view-header__header">
        <div id="header-action"></div>
      </div>
      <div class="view-header__footer">
        <Btn
          class="back-btn"
          round
          grow
          icon="arrow-left"
          :to="{
            name: 'essaisChildren',
          }"
        />
        <h1 class="page-title">
          {{ essai.nom }} - {{ $route.meta.title }}
          <span v-if="essai.protocole?.bpe">BPE</span>
        </h1>
      </div>
    </div>

    <div class="view-body">
      <router-view
        v-if="essai.id"
        @updateEssais="getEssai"
        :essai="essai"
      ></router-view>
    </div>

    <NavigationDropdownEssaiChild :protocol="essai?.protocole" />
  </template>

<script>
import Btn from '@/components/base/Btn.vue'
import NavigationDropdownEssaiChild from '@/views/componentsViews/navigationDropdown/NavigationDropDownEssaiChild.vue'

export default {
  name: 'EssaiView',

  components: {
    NavigationDropdownEssaiChild,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      essai: {},
    }
  },

  created() {
    this.getEssai()
  },

  methods: {
    getEssai() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then(
        (response) => {
          this.essai = response.data
        },
        (errorResponse) => {
          if (errorResponse.meta.status === 404) {
            this.$router.push({ name: 'notFound' })
          }
        },
      )
    },
  },
}
</script>

  <style scoped>
  </style>
